<template>
    <sign-layout class="new-pass-page" :processing="processing" :error="error">

        <template #default>
            <div class="new-pass-page__content">
                <h3 class="new-pass-page__title">Create new password</h3>

                <new-pass-form
                    class="new-pass-page__form"
                    :disabled="processing || !is_valid_code"
                    @setProcessing="setProcessing"
                    @setError="setError"
                />

                <p>
                    <router-link class="new-pass-page__alternative" :to="{name: 'sign-in'}">
                        Back to Login
                    </router-link>
                </p>
            </div>
        </template>

    </sign-layout>
</template>

<script>
import { mapGetters } from 'vuex'

import SignLayout from '@/layouts/sign.vue'
import NewPassForm from './form.vue'

export default {
    components: {
        SignLayout,
        NewPassForm,
    },
    data() {
        return {
            processing: false,

            error: null,

            is_valid_code: true,
        }
    },
    created() {
        this.validateCode()
    },
    computed: {
        code() {
            return this.$route.params.code
        },
    },
    methods: {
        setProcessing(value) {
            this.processing = value
        },

        setError(error = null) {
            this.error = error
        },

        validateCode() {
            this.setProcessing(true)

            // TODO
            // implement API to validate code
            setTimeout(() => {
                if (!+this.code) {
                    this.is_valid_code = false

                    this.error = 'Code is not valid'
                }

                this.setProcessing(false)
            }, 1000)
        },
    },
}
</script>

<style lang="scss">
</style>