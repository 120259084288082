<template>
    <app-form class="form--new-pass">

        <app-input
            class="form__input form__input--pass"

            v-model="user.passnew"

            label="New password"
            type="password"
            :icon="'lock'"

            :error="errors.fields.passnew"
            :disabled="disabled"

            @change="showNextError('passnew')"
        />

        <app-input
            class="form__input form__input--confirm-pass"

            v-model="user.confirm_pass"

            label="Confirm new password"
            type="password"
            :icon="'lock'"

            :error="errors.fields.confirm_pass"
            :disabled="disabled"

            @change="showNextError('confirm_pass')"
        />

        <div class="form__btns">
            <button class="form__button btn" :disabled="disabled" @click.prevent="create">Update Password</button>
        </div>
    </app-form>
</template>

<script>
import appInput from '@/components/app-input-sign'
import appForm from '@/components/app-form'

import UserDetailsValidator from '@/validators/user-details-validator'

export default {
    components: {
        appInput,
        appForm,
    },

    props: {
        disabled: { type: Boolean, default: false },
    },

    data() {
        return {
            user: {
                passnew: '',
                confirm_pass: '',
            },

            errors: {
                fields: {},
            },
        }
    },

    methods: {
        create() {
            if (this.validation()) {
                this.$emit('setProcessing', true)

                this.$emit('setError', null)

                // this.$store.dispatch('CreatePass', this.user).then(() => {
                //     this.$emit('setProcessing', false)

                //     this.$router.push({ name: 'home' })
                // })
                // .catch(error => {
                //     this.$emit('setError', error)

                //     this.$emit('setProcessing', false)
                // })
            }
        },

        validation() {
            let is_valid = true

            this.errors = { fields: {},}
            this.$emit('setError', null)

            const values = {

                passnew: {
                    value: this.user.passnew,
                    name: 'New password',
                },

                confirm_pass: {
                    value: this.user.confirm_pass,
                },
            }

            for (const key in values) {
                if (UserDetailsValidator.isRuleExists(key)) {
                    if (UserDetailsValidator.isInvalid(key, values[key].value, values[key])) {
                        this.errors.fields[ key ] = UserDetailsValidator.getError()

                        is_valid = false
                    }
                }
            }

            if (this.user.passnew !== this.user.confirm_pass) {
                this.errors.fields.confirm_pass = this.user.confirm_pass ? 'Password does not match' : 'Please confirm new password'

                is_valid = false
            }

            return is_valid

            // return !this.showNextError()
        },

        showNextError(except) {
            if (except) {
                delete this.errors.fields[ except ]
            }
        },
    },
}
</script>

<style lang="scss">
</style>